import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export function ParWithStrokes({ strokes, children }) {
  const CenteredGrid = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  return (
    <Grid container spacing={0}>
      {/* Dots representing strokes given */}
      <CenteredGrid item xs={12}>
        <Typography fontSize={10} align="center" sx={{ whiteSpace: "nowrap" }}>
          {strokes > 0
            ? (String.fromCharCode("0x2022") + " ").repeat(strokes)
            : String.fromCharCode("0x00A0")}
        </Typography>
      </CenteredGrid>

      {/* Par */}
      <CenteredGrid item xs={12}>
        {children}
      </CenteredGrid>
    </Grid>
  );
}
