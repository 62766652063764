import { createContext, useCallback, useContext, useState } from "react";

import { getData } from "./api_utils";
import { useErrorContext, CreateError } from "./error_provider";

const SCORE_KEYS = ["scores", "team_scores", "stats"];

export const GamesConfigContext = createContext(null);
export const RefreshCallbackContext = createContext(() => {});
export const ScoresContext = createContext(null);

export function DataProvider({ children }) {
  const [gamesConfig, setGamesConfig] = useState(null);
  const [scores, setScores] = useState(null);
  const { error, setError } = useErrorContext();

  const RefreshCallback = useCallback(() => {
    if (error) return;

    return getData()
      .then((data) => {
        const curGamesConfig = FilterScores(data);
        if (JSON.stringify(curGamesConfig) !== JSON.stringify(gamesConfig)) {
          setGamesConfig(curGamesConfig);
        }

        const curScores = FilterNonScores(data);
        if (JSON.stringify(curScores) !== JSON.stringify(scores)) {
          setScores(curScores);
        }
      })
      .catch((err) => {
        setError(CreateError("Failed to fetch data", err.message));
      });
  }, [error, gamesConfig, scores, setError]);

  if (error === null && scores === null) {
    RefreshCallback();
  }

  return (
    <RefreshCallbackContext.Provider value={RefreshCallback}>
      <GamesConfigContext.Provider value={gamesConfig}>
        <ScoresContext.Provider value={scores}>
          {children}
        </ScoresContext.Provider>
      </GamesConfigContext.Provider>
    </RefreshCallbackContext.Provider>
  );
}

export function useGamesConfigContext() {
  const context = useContext(GamesConfigContext);
  if (context === undefined) {
    throw new Error(
      "useGamesConfigContext must be used within a GamesConfigProvider"
    );
  }
  return context;
}

export function useRefreshCallbackContext() {
  const context = useContext(RefreshCallbackContext);
  if (context === undefined) {
    throw new Error(
      "useRefreshCallbackContext must be used within a RefreshCallbackProvider"
    );
  }
  return context;
}

export function useScoresContext() {
  const context = useContext(ScoresContext);
  if (context === undefined) {
    throw new Error("useScoresContext must be used within a ScoresProvider");
  }
  return context;
}

function FilterScores(data) {
  return Object.fromEntries(
    Object.entries(data).map(([year, yearData]) => [
      year,
      Object.fromEntries(
        Object.entries(yearData)
          .filter(([key, value]) => !SCORE_KEYS.includes(key))
          .map(([key, value]) => {
            if (key !== "games") {
              return [key, value];
            }

            return [key, value.map((game) => game)];
          })
      ),
    ])
  );
}

function FilterNonScores(data) {
  return Object.fromEntries(
    Object.entries(data).map(([year, yearData]) => [
      year,
      Object.fromEntries(
        Object.entries(yearData).filter(([key, value]) =>
          SCORE_KEYS.includes(key)
        )
      ),
    ])
  );
}
