import { Box, Stack, Typography } from "@mui/material";

import { CoopScorecard } from "../components/group_games";
import { Table } from "../components/table";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { GetUsername } from "../utils";
import {
  GrossRow,
  IndexRow,
  MedalHoleScorecard,
  NetRow,
  ParRow,
  PointsRow,
  SubColumns,
} from "./medal";
import { GetCourse } from "./utils";

export function Scramble({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig?.[year] || {};
  const yearScores = scores?.[year] || {};
  const gameScores = yearScores?.scores?.[game.game_id] || {};

  const { course, tees } = GetCourse(
    game.scramble.config.course,
    game.scramble.config.tees,
    yearGamesConfig.courses
  );

  const teamScores = game.scramble.teams.map((team) => {
    const user = team.members.find((user) => user in gameScores);
    const score = gameScores?.[user] || {};
    return {
      team: team.members,
      score: score,
    };
  });

  const isRyderCupStyle = !!yearGamesConfig?.teams;
  const Side = (team) => {
    if (!isRyderCupStyle) return "left";
    const ryderCupTeams = Object.entries(yearGamesConfig.teams).sort();
    return team.some((userId) => ryderCupTeams[0][1].members.includes(userId))
      ? "left"
      : "right";
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      {teamScores
        .sort((a, b) => a.score.points - b.score.points)
        .reverse()
        .map(({ team, score }, i) => {
          const columns = SubColumns(course, tees);
          const rows = [
            IndexRow(score),
            ParRow(score),
            GrossRow(score),
            NetRow(score),
            PointsRow(score),
          ];
          const subcomponent = (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={2}
            >
              <Table columns={columns} rows={rows} />
            </Box>
          );

          return (
            <CoopScorecard
              side={Side(team)}
              players={team.map((user) => GetUsername(user, yearGamesConfig))}
              progress={score.gross.filter((x) => x).length}
              points={score.points}
              hole_points={score.hole_points}
              course={course}
              subcomponent={subcomponent}
              key={i}
            />
          );
        })}
      <Rules game={game} />
    </Stack>
  );
}

export function ScrambleScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.scramble.config.course,
    game.scramble.config.tees,
    yearGamesConfig.courses
  );

  const team =
    game.scramble.teams.find((team) => team.members.includes(userId))
      ?.members || [];
  const score = gameScores[userId];

  const columns = SubColumns(course, tees);
  const rows = [
    IndexRow(score),
    ParRow(score),
    GrossRow(score),
    NetRow(score),
    PointsRow(score),
  ];
  const subcomponent = (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <Table columns={columns} rows={rows} />
    </Box>
  );

  const isRyderCupStyle = !!yearGamesConfig?.teams;
  const Side = (team) => {
    if (!isRyderCupStyle) return "left";
    const ryderCupTeams = Object.entries(yearGamesConfig.teams).sort();
    return team.some((userId) => ryderCupTeams[0][1].members.includes(userId))
      ? "left"
      : "right";
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <CoopScorecard
        side={Side(team)}
        players={team.map((user) => GetUsername(user, yearGamesConfig))}
        progress={score.gross.filter((x) => x).length}
        points={score.points}
        hole_points={score.hole_points}
        course={course}
        subcomponent={subcomponent}
      />
      <MedalHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={score}
      />
    </Stack>
  );
}

function Rules({ game }) {
  return (
    <Typography fontFamily="Tiempos Headline" fontSize={24} mt={6}>
      {`${game.name} Rules`}
    </Typography>
  );
}
