import { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Table } from "../components/table";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { currentYear } from "../env_utils";
import { ErrorAlert } from "../error_alert";
import { useErrorContext } from "../error_provider";
import { BestBall } from "../games/best_ball";
import { BestBallVersus } from "../games/best_ball_versus";
import { Bonus2024 } from "../games/bonus_2024";
import { CustomGame } from "../games/custom_game";
import { Medal } from "../games/medal";
import { MedalVersus } from "../games/medal_versus";
import { isRyderCupStyle, RyderCupScore } from "../games/ryder_cup";
import { Scramble } from "../games/scramble";
import { ScrambleVersus } from "../games/scramble_versus";
import { BASE_COLUMN_STYLE, BASE_COLUMNS, Sum } from "../games/utils";
import { Wolf } from "../games/wolf";
import { Loading } from "../loading";
import { GetHandicap, GetUsername } from "../utils";

export default function Leaderboard() {
  const [page, setPage] = useState({ year: null, round: null });
  const { error } = useErrorContext();
  const gamesConfig = useGamesConfigContext();

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (!gamesConfig) {
    return <Loading />;
  }

  const yearList = Object.keys(gamesConfig)
    .sort()
    .reverse()
    .map((year) => ({
      value: year,
      label: year,
    }));
  const roundLists = Object.fromEntries(
    Object.entries(gamesConfig).map(([year, year_data]) => {
      return [
        year,
        [
          { value: "tournament", label: "Tournament" },
          ...year_data.games.map((game) => ({
            value: game.game_id,
            label: game.name,
          })),
        ],
      ];
    })
  );

  if (page.year === null) {
    const year = currentYear();
    const round = roundLists[year][0].value;
    setPage({ year: year, round: round });
    return <Loading />;
  }

  const yearGamesConfig = gamesConfig[page.year];

  return (
    <Box m={2}>
      <Stack justifyContent="space-between" spacing={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography fontFamily="Tiempos Headline" fontSize={24}>
            Leaderboard
          </Typography>
          <PageSelect
            page={page.year}
            pageList={yearList}
            onChange={(event) => {
              const year = event.target.value;
              setPage({
                year: year,
                round: roundLists[year][0].value,
              });
            }}
            formControlProps={{ size: "small", fullWidth: false }}
          />
        </Stack>
        {isRyderCupStyle(yearGamesConfig) && <RyderCupScore year={page.year} />}
        <PageSelect
          label="Round"
          page={page.round}
          pageList={roundLists[page.year]}
          onChange={(event) =>
            setPage((prevPage) => ({
              year: prevPage.year,
              round: event.target.value,
            }))
          }
        />
        {page.round === "tournament" && (
          <TournamentPage year={page.year} key={page.year + "tournament"} />
        )}
        {gamesConfig[page.year].games.map(
          (game) =>
            page.round === game.game_id && (
              <GamePage
                game={game}
                year={page.year}
                key={page.year + game.game_id}
              />
            )
        )}
      </Stack>
    </Box>
  );
}

function TournamentPage({ year }) {
  const theme = useTheme();
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];

  const userScores = Object.fromEntries(
    Object.keys(yearGamesConfig.users).map((user) => [
      user,
      Object.entries(yearScores.scores).map(([gameId, gameScores]) => ({
        game_id: gameId,
        score: gameScores[user],
      })),
    ])
  );

  const columns = [
    ...BASE_COLUMNS,
    ...yearGamesConfig.games.map((game, index) => ({
      id: game.game_id,
      label: game.shortname,
      align: "right",
      sortable: ["desc", "asc"],
      sx: { ...BASE_COLUMN_STYLE, flexGrow: 0 },
    })),
  ];

  const rows = Object.entries(userScores).map(([user, curUserScores]) => {
    return {
      name: `${GetUsername(user, yearGamesConfig)} (${GetHandicap(user, yearGamesConfig)})`,
      total: Sum(curUserScores.map((x) => x.score.points)),
      ...Object.fromEntries(
        curUserScores.map((x) => [x.game_id, x.score.points])
      ),
    };
  });

  return (
    <Paper
      variant="outlined"
      sx={{ borderColor: theme.palette.misc.borderColor }}
    >
      <Box p={2}>
        <Table columns={columns} rows={rows} />
      </Box>
    </Paper>
  );
}

function GamePage({ game, year }) {
  if ("medal" in game) {
    return <Medal game={game} year={year} />;
  } else if ("scramble" in game) {
    return <Scramble game={game} year={year} />;
  } else if ("best_ball" in game) {
    return <BestBall game={game} year={year} />;
  } else if ("medal_versus" in game) {
    return <MedalVersus game={game} year={year} />;
  } else if ("scramble_versus" in game) {
    return <ScrambleVersus game={game} year={year} />;
  } else if ("best_ball_versus" in game) {
    return <BestBallVersus game={game} year={year} />;
  } else if ("wolf" in game) {
    return <Wolf game={game} year={year} />;
  } else if ("bonus_2024" in game) {
    return <Bonus2024 game={game} year={year} />;
  } else if ("custom_game" in game) {
    return <CustomGame game={game} year={year} />;
  }

  return <></>;
}

function PageSelect({ label, page, pageList, onChange, formControlProps }) {
  return (
    <FormControl fullWidth {...formControlProps}>
      <InputLabel>{label}</InputLabel>
      <Select value={page} label={label} onChange={onChange}>
        {pageList.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ p: 2 }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
