import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  setPersistence,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD6jIty8TZ9LX8-O0cL0AsdG51A8f_zW0A",
  authDomain: "ball-shaggers-invitational.firebaseapp.com",
  projectId: "ball-shaggers-invitational",
  storageBucket: "ball-shaggers-invitational.appspot.com",
  messagingSenderId: "683826154025",
  appId: "1:683826154025:web:148199f172b7c2aece2a38",
  measurementId: "G-P080V2E69P",
};

export function initFirebase() {
  initializeApp(firebaseConfig);
  setPersistence(getAuth(), browserLocalPersistence);
}

export async function login() {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  provider.setCustomParameters({
    prompt: "select_account",
  });

  return signInWithPopup(getAuth(), provider);
}
