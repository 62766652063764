import { createContext, useContext, useState } from "react";

const ErrorContext = createContext({ error: null, setError: null });

export function CreateError(title, msg) {
  return { title, msg };
}

export function ErrorProvider({ children }) {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
}

export function useErrorContext() {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error("useErrorContext must be used within a ErrorProvider");
  }
  return context;
}
