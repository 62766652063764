import { Box, Stack } from "@mui/material";

import { VersusScorecard } from "../components/group_games";
import { Table } from "../components/table";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { GetUsername } from "../utils";
import {
  IndexRow,
  GrossRow,
  MedalHoleScorecard,
  NetRow,
  ParRow,
  PointsRow,
  SubColumns,
} from "./medal";
import { GetCourse } from "./utils";

export function MedalVersus({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig?.[year] || {};
  const yearScores = scores?.[year] || {};
  const gameScores = yearScores?.scores?.[game.game_id] || {};

  const { course, tees } = GetCourse(
    game.medal_versus.config.medal_config.course,
    game.medal_versus.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const groups = game.medal_versus.groups.filter((group) =>
    group.teams.every((team) => team.members.length)
  );

  return (
    <Stack justifyContent="space-between" spacing={2}>
      {groups.map((group, i) => {
        const userLeft = group.teams[0].members[0];
        const userRight = group.teams[1].members[0];
        const nameLeft = GetUsername(userLeft, yearGamesConfig);
        const nameRight = GetUsername(userRight, yearGamesConfig);

        const scoreLeft = gameScores?.[userLeft] || {};
        const scoreRight = gameScores?.[userRight] || {};

        const columns = SubColumns(course, tees);
        const rows = [
          IndexRow(scoreLeft.score),
          ParRow(scoreLeft.score),
          {},
          { category: nameLeft },
          GrossRow(scoreLeft.score),
          NetRow(scoreLeft.score),
          PointsRow(scoreLeft.score),
          {},
          { category: nameRight },
          GrossRow(scoreRight.score),
          NetRow(scoreRight.score),
          PointsRow(scoreRight.score),
        ];

        const subcomponent = (
          <Box display="flex" alignItems="center" justifyContent="center" p={2}>
            <Table columns={columns} rows={rows} />
          </Box>
        );

        return (
          <VersusScorecard
            variant={game.medal_versus.config.versus_mode}
            playersLeft={[nameLeft]}
            playersRight={[nameRight]}
            progress={Math.min(
              scoreLeft.score.gross.filter((x) => x).length,
              scoreRight.score.gross.filter((x) => x).length
            )}
            holePointsLeft={scoreLeft.hole_points.map((x, i) =>
              scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
            )}
            holePointsRight={scoreRight.hole_points.map((x, i) =>
              scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
            )}
            course={course}
            subcomponent={subcomponent}
            key={i}
          />
        );
      })}
    </Stack>
  );
}

export function MedalVersusScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.medal_versus.config.medal_config.course,
    game.medal_versus.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const group =
    game.medal_versus.groups.find((group) =>
      group.teams.find((team) => team.members.includes(userId))
    ) || {};
  const score = gameScores[userId] || {};

  const userLeft = group.teams[0].members[0];
  const userRight = group.teams[1].members[0];
  const nameLeft = GetUsername(userLeft, yearGamesConfig);
  const nameRight = GetUsername(userRight, yearGamesConfig);

  const scoreLeft = gameScores[userLeft];
  const scoreRight = gameScores[userRight];

  const columns = SubColumns(course, tees);
  const rows = [
    IndexRow(scoreLeft.score),
    ParRow(scoreLeft.score),
    {},
    { category: nameLeft },
    GrossRow(scoreLeft.score),
    NetRow(scoreLeft.score),
    PointsRow(scoreLeft.score),
    {},
    { category: nameRight },
    GrossRow(scoreRight.score),
    NetRow(scoreRight.score),
    PointsRow(scoreRight.score),
  ];

  const subcomponent = (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <Table columns={columns} rows={rows} />
    </Box>
  );

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <VersusScorecard
        variant={game.medal_versus.config.versus_mode}
        playersLeft={[nameLeft]}
        playersRight={[nameRight]}
        progress={Math.min(
          scoreLeft.score.gross.filter((x) => x).length,
          scoreRight.score.gross.filter((x) => x).length
        )}
        holePointsLeft={scoreLeft.hole_points.map((x, i) =>
          scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
        )}
        holePointsRight={scoreRight.hole_points.map((x, i) =>
          scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
        )}
        course={course}
        subcomponent={subcomponent}
      />
      <MedalHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={score.score}
      />
    </Stack>
  );
}
