import { Box, Paper, Stack, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { CenteredBox } from "../components/base";

export default function Home() {
  const theme = useTheme();
  const localTheme = createTheme({
    typography: {
      ...theme.typography,
      heading: {
        fontFamily: "Tiempos Headline",
        fontSize: 24,
      },
      invitationBody: {
        fontFamily: "Invitation",
        fontSize: 18,
        textAlign: "center",
      },
      invitationEmphasis: {
        fontFamily: "Invitation",
        fontSize: 24,
        textAlign: "center",
      },
      signature: {
        fontFamily: "Chairman",
        fontSize: 24,
        textAlign: "center",
      },
      signatureTitle: {
        fontFamily: "Chairman",
        fontSize: 18,
        textAlign: "center",
      },
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Box display="flex" flexDirection="column" p={2}>
        <Typography variant="heading">Welcome</Typography>

        <CenteredBox mt={4}>
          <Paper elevation={8} square={true}>
            <Box p={2} pl={8} pr={8}>
              <Stack alignItems="center">
                <Box component="img" src="masters/logo_bw.png" height="100px" />
                <Typography variant="invitationEmphasis">
                  The Board of Governors
                </Typography>
                <Typography variant="invitationBody">of the</Typography>
                <Typography variant="invitationEmphasis">
                  Ball Shaggers
                </Typography>
                <Typography variant="invitationBody">
                  respectfully requests <br />
                  the honor of your presence at the <br />
                  Two Thousand and Twenty Five
                </Typography>
                <Typography variant="invitationEmphasis">
                  Ball Shaggers Invitational
                </Typography>
                <Typography variant="invitationBody">
                  to be held at <br />
                  Palm Springs, California <br />
                  the eighteenth through the <br />
                  twenty-second of January
                </Typography>

                <Typography variant="signature" mt={4}>
                  Peter Berczi
                </Typography>

                <Typography variant="signatureTitle" lineHeight={0.8}>
                  Shagger
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </CenteredBox>

        <Typography mt={2}>Dear Ball Shaggers,</Typography>

        <Typography mt={2}>
          It is with great honor and absolutely no dignity that we welcome you
          to Palm Springs for this prestigious gathering of mediocre golf and
          world-class trash talk. Over the next five days, we will embark on a
          journey of questionable swings, lost balls, and egregiously inflated
          handicaps, all in the name of camaraderie and the sacred pursuit of
          avoiding triple bogeys. This esteemed tournament, now in its third
          year, continues to uphold the traditions that define us: bending the
          rules, blaming the wind, and sinking that one miracle putt that keeps
          you bragging until next year. As we gather to shag balls and shoot the
          proverbial shot, remember—this isn’t just golf. It’s a celebration of
          friendship, bad decisions, and the undeniable fact that none of us are
          making the PGA Tour. Welcome, gentlemen, to the third Ball Shaggers
          Invitational. Let the shenanigans begin.
        </Typography>

        <Typography variant="heading" mt={6}>
          Important Information
        </Typography>
        <Typography mt={2} ml={2}>
          <b>When:</b> January 18-22
        </Typography>
        <Typography ml={2}>
          <b>Where:</b> Palm Springs, California
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
