export const BASE_COLUMN_STYLE = { p: 1, pt: 2, pb: 2 };
export const BASE_COLUMNS = [
  {
    id: "name",
    label: "Name",
    align: "left",
    sx: {
      ...BASE_COLUMN_STYLE,
      flexGrow: 1,
      width: "auto",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
  },
  {
    id: "total",
    label: "T",
    align: "right",
    sortable: ["desc", "asc"],
    sorted: "desc",
    sx: { ...BASE_COLUMN_STYLE, flexGrow: 0 },
  },
];

export const BASE_SUBCOLUMN_STYLE = { width: "auto", p: 1, pt: 0.5, pb: 0.5 };
export const BASE_SUBCOLUMNS = [
  {
    id: "category",
    label: "Category",
    align: "left",
    sx: {
      ...BASE_SUBCOLUMN_STYLE,
      flexGrow: 1,
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
  },
  {
    id: "total",
    label: "Total",
    align: "right",
    sx: { ...BASE_SUBCOLUMN_STYLE, flexGrow: 0 },
  },
];

export function GetCourse(courseId, tees, courses) {
  if (!(courseId in courses)) {
    throw new Error(`${courseId} not found (courses: ${Object.keys(courses)})`);
  }

  const course = courses[courseId];
  if (!(tees in course.tees)) {
    throw new Error(`${tees} not found (tees: ${Object.keys(course.tees)})`);
  }

  return { course: course, tees: course.tees[tees] };
}

export function Sum(values) {
  if (!values) return 0;
  return values.reduce((a, b) => a + b, 0);
}
