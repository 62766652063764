import React from "react";
import { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAuth } from "firebase/auth";
import Plot from "react-plotly.js";

import { ErrorAlert } from "../error_alert";
import { Loading } from "../loading";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { useErrorContext } from "../error_provider";

export default function Stats() {
  const theme = useTheme();
  const { error } = useErrorContext();
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (!gamesConfig) {
    return <Loading />;
  }

  const xAxis = Object.keys(gamesConfig);
  const legendMap = {
    p: "par",
    b: "birdie",
    e: "eagle",
    a: "albatross",
    c: "condor",
    np: "net par",
    nb: "net birdie",
    ne: "net eagle",
    na: "net albatross",
    nc: "net condor",
    hpp: "par",
    hpb: "birdie",
    hpe: "eagle",
    hpa: "albatross",
    hpc: "condor",
    hpnp: "net par",
    hpnb: "net birdie",
    hpne: "net eagle",
    hpna: "net albatross",
    hpnc: "net condor",
  };

  const AggregatePlots = () => (
    <Stack justifyContent="space-between" spacing={2}>
      <Typography fontFamily="Tiempos Headline" fontSize={18} mb={2}>
        Aggregate
      </Typography>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <YearlyBarPlot
          data={["p", "b", "e", "a", "c"].map((category) => {
            const agg = xAxis.map(
              (x) => scores?.[x]?.stats?.aggregate?.[category]
            );
            if (!agg.some((x) => !!x)) return {};

            return {
              type: "bar",
              x: xAxis,
              y: agg,
              name: legendMap[category],
              hoverinfo: "y",
            };
          })}
          title="Gross Shots vs. Year (Field)"
        />
      </Paper>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <YearlyBarPlot
          data={["hpp", "hpb", "hpe", "hpa", "hpc"].map((category) => {
            const agg = xAxis.map(
              (x) => scores?.[x]?.stats?.aggregate?.[category]
            );
            if (!agg.some((x) => !!x)) return {};

            return {
              type: "bar",
              x: xAxis,
              y: agg,
              name: legendMap[category],
              hoverinfo: "y",
            };
          })}
          title="Gross Holes Per vs. Year (Field)"
          yAxisLabel="holes per"
        />
      </Paper>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <YearlyBarPlot
          data={["np", "nb", "ne", "na", "nc"].map((category) => {
            const agg = xAxis.map(
              (x) => scores?.[x]?.stats?.aggregate?.[category]
            );
            if (!agg.some((x) => !!x)) return {};

            return {
              type: "bar",
              x: xAxis,
              y: agg,
              name: legendMap[category],
              hoverinfo: "y",
            };
          })}
          title="Net Shots vs. Year (Field)"
        />
      </Paper>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <YearlyBarPlot
          data={["hpnp", "hpnb", "hpne", "hpna", "hpnc"].map((category) => {
            const agg = xAxis.map(
              (x) => scores?.[x]?.stats?.aggregate?.[category]
            );
            if (!agg.some((x) => !!x)) return {};

            return {
              type: "bar",
              x: xAxis,
              y: agg,
              name: legendMap[category],
              hoverinfo: "y",
            };
          })}
          title="Net Holes Per vs. Year (Field)"
          yAxisLabel="holes per"
        />
      </Paper>
    </Stack>
  );

  const PlayerPlots = () => {
    const [user, setUser] = useState(null);
    const userIds = Object.keys(gamesConfig)
      .map((year) => Object.keys(gamesConfig[year].users))
      .flat()
      .filter((x, i, a) => a.indexOf(x) === i);

    const Name = (userId) => {
      const name = Object.values(gamesConfig)
        .map((yearGamesConfig) => yearGamesConfig.users?.[userId]?.name)
        .filter((x) => !!x)
        .at(-1);
      const [first, ...rest] = name.split(" ");
      let capitalizedName = "";
      capitalizedName += first[0].toUpperCase() + first.slice(1);
      if (rest.length) {
        const last = rest.join(" ");
        capitalizedName += " " + last[0].toUpperCase() + last.slice(1);
      }
      return capitalizedName;
    };

    const userList = userIds.map((userId, i) => ({
      value: userId,
      label: Name(userId),
    }));

    if (user === null) {
      setUser(getAuth().currentUser?.email || userList[0].value);
      return <Loading />;
    }

    return (
      <Stack justifyContent="space-between" spacing={2}>
        <Typography fontFamily="Tiempos Headline" fontSize={18} mb={2}>
          Player
        </Typography>
        <PageSelect
          page={user}
          pageList={userList}
          onChange={(event) => setUser(event.target.value)}
          formControlProps={{ size: "small", fullWidth: false }}
        />
        <Paper
          variant="outlined"
          sx={{ borderColor: theme.palette.misc.borderColor }}
        >
          <YearlyBarPlot
            data={["p", "b", "e", "a", "c"].map((category) => {
              const stats = xAxis.map(
                (x) => scores?.[x]?.stats?.player?.[user]?.[category]
              );
              if (!stats.some((x) => !!x)) return {};

              return {
                type: "bar",
                x: xAxis,
                y: stats,
                name: legendMap[category],
                hoverinfo: "y",
              };
            })}
            title={`Gross Shots vs. Year<br>(${Name(user)})`}
          />
        </Paper>
        <Paper
          variant="outlined"
          sx={{ borderColor: theme.palette.misc.borderColor }}
        >
          <YearlyBarPlot
            data={["hpp", "hpb", "hpe", "hpa", "hpc"].map((category) => {
              const stats = xAxis.map(
                (x) => scores?.[x]?.stats?.player?.[user]?.[category]
              );
              if (!stats.some((x) => !!x)) return {};

              return {
                type: "bar",
                x: xAxis,
                y: stats,
                name: legendMap[category],
                hoverinfo: "y",
              };
            })}
            title={`Gross Holes Per vs. Year<br>(${Name(user)})`}
            yAxisLabel="holes per"
          />
        </Paper>
        <Paper
          variant="outlined"
          sx={{ borderColor: theme.palette.misc.borderColor }}
        >
          <YearlyBarPlot
            data={["np", "nb", "ne", "na", "nc"].map((category) => {
              const stats = xAxis.map(
                (x) => scores?.[x]?.stats?.player?.[user]?.[category]
              );
              if (!stats.some((x) => !!x)) return {};

              return {
                type: "bar",
                x: xAxis,
                y: stats,
                name: legendMap[category],
                hoverinfo: "y",
              };
            })}
            title={`Net Shots vs. Year<br>(${Name(user)})`}
          />
        </Paper>
        <Paper
          variant="outlined"
          sx={{ borderColor: theme.palette.misc.borderColor }}
        >
          <YearlyBarPlot
            data={["hpnp", "hpnb", "hpne", "hpna", "hpnc"].map((category) => {
              const stats = xAxis.map(
                (x) => scores?.[x]?.stats?.player?.[user]?.[category]
              );
              if (!stats.some((x) => !!x)) return {};

              return {
                type: "bar",
                x: xAxis,
                y: stats,
                name: legendMap[category],
                hoverinfo: "y",
              };
            })}
            title={`Net Holes Per vs. Year<br>(${Name(user)})`}
            yAxisLabel="holes per"
          />
        </Paper>
      </Stack>
    );
  };

  return (
    <Box m={2}>
      <Stack justifyContent="space-between" spacing={2}>
        <Typography fontFamily="Tiempos Headline" fontSize={24} mb={4}>
          Statistics
        </Typography>
        <AggregatePlots />
        <PlayerPlots />
      </Stack>
    </Box>
  );
}

function PageSelect({ label, page, pageList, onChange, formControlProps }) {
  return (
    <FormControl fullWidth {...formControlProps}>
      <InputLabel>{label}</InputLabel>
      <Select value={page} label={label} onChange={onChange}>
        {pageList.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ p: 2 }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function YearlyBarPlot({ data, title, yAxisLabel }) {
  const theme = useTheme();

  return (
    <Box width="100%" sx={{ overflow: "hidden" }}>
      <Plot
        data={data}
        layout={{
          title: { text: title },
          xaxis: {
            title: { text: "year" },
            tickmode: "linear",
            tickangle: 45,
          },
          yaxis: { title: { text: yAxisLabel } },
          legend: {
            orientation: "h",
            yanchor: "top",
            y: -0.5,
            xanchor: "center",
            x: 0.5,
          },
          showlegend: true,
          font: { family: "Tiempos" },
          width: window.innerWidth - theme.spacing(6).slice(0, -2),
          height: 0.85 * window.innerWidth,
          margin: {
            t: theme.spacing(8).slice(0, -2),
            l: theme.spacing(8).slice(0, -2),
            r: 0,
          },
          scrollZoom: false,
          dragmode: false,
        }}
        config={{ displayModeBar: false }}
      />
    </Box>
  );
}
