import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { submitScorecardUpdate } from "../api_utils";
import { Table } from "../components/table";
import {
  useGamesConfigContext,
  useRefreshCallbackContext,
  useScoresContext,
} from "../data_provider";
import { GetHandicap, GetUsername } from "../utils";
import { GrossRow, NetRow, PointsRow, SubColumns } from "./medal";
import { BASE_COLUMN_STYLE, BASE_COLUMNS, GetCourse } from "./utils";

export function Wolf({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();
  const theme = useTheme();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.wolf.config.medal_config.course,
    game.wolf.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const columns = [
    BASE_COLUMNS.find((col) => col.id === "name"),
    {
      id: "group",
      label: "Group",
      align: "right",
      sortable: ["desc", "asc"],
      sx: { ...BASE_COLUMN_STYLE, flexGrow: 0 },
    },
    BASE_COLUMNS.find((col) => col.id === "total"),
  ];
  const rows = Object.entries(gameScores).map(([user, score]) => {
    const group = game.wolf.groups.findIndex((group) =>
      group.members.includes(user)
    );

    const subcolumns = SubColumns(course, tees);
    const subrows = [
      { category: "Index", ...course.hole_index },
      { category: "Par", ...course.hole_par },
      PointsRow(score),
      ...Object.entries(score.scores)
        .map(([u, s]) => [
          {},
          { category: GetUsername(u, yearGamesConfig), ...WolfRow(u, score) },
          GrossRow(s),
          NetRow(s),
        ])
        .flat(),
    ];

    return {
      name: `${GetUsername(user, yearGamesConfig)} (${GetHandicap(user, yearGamesConfig)})`,
      group: group + 1,
      total: score.points,
      subComponent: <Table columns={subcolumns} rows={subrows} />,
    };
  });

  return (
    <Paper
      variant="outlined"
      sx={{ borderColor: theme.palette.misc.borderColor }}
    >
      <Box p={2}>
        <Table columns={columns} rows={rows} />
      </Box>
    </Paper>
  );
}

export function WolfScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();
  const theme = useTheme();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.wolf.config.medal_config.course,
    game.wolf.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const score = gameScores[userId];

  const subcolumns = SubColumns(course, tees);
  const subrows = [
    { category: "Index", ...course.hole_index },
    { category: "Par", ...course.hole_par },
    PointsRow(score),
    ...Object.entries(score.scores)
      .map(([u, s]) => [
        {},
        { category: GetUsername(u, yearGamesConfig), ...WolfRow(u, score) },
        GrossRow(s),
        NetRow(s),
      ])
      .flat(),
  ];

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <Box p={2}>
          <Table columns={subcolumns} rows={subrows} />
        </Box>
      </Paper>
      <WolfHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={score.scores[userId]}
        wolf={score.wolf}
      />
    </Stack>
  );
}

function WolfHoleScorecard({ gameId, year, course, score, wolf }) {
  const [hole, setHole] = useState(null);
  const [strokes, setStrokes] = useState(null);
  const [holeWolf, setHoleWolf] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const refreshCallback = useRefreshCallbackContext();
  const theme = useTheme();

  useEffect(() => {
    setStrokes(score.gross[hole - 1]);
    setHoleWolf(wolf[hole - 1]);
  }, [hole, score, wolf]);

  if (!hole) setHole(1);

  const Submit = () => {
    setSubmitting(true);
    return submitScorecardUpdate({
      year: `${year}`,
      game_id: gameId,
      wolf: { medal: { hole: hole - 1, strokes: strokes }, wolf: holeWolf },
    })
      .then(() => refreshCallback())
      .then(() => setSubmitting(false))
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <Stack alignItems="center">
      <Stack direction="row" justifyContent="center">
        <Stack direction="row" alignItems="center">
          <IconButton
            size="large"
            onClick={() => setHole((prev) => Math.max(prev - 1, 1))}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <Typography fontFamily="Tiempos Headline" fontSize={24}>
            Hole {hole}
          </Typography>
          <IconButton
            size="large"
            onClick={() =>
              setHole((prev) => Math.min(prev + 1, course.hole_par.length))
            }
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Stack>
      <Typography fontSize={10} sx={{ mt: 2 }}>
        Strokes
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "144px",
          height: "50px",
          borderRadius: "24px",
          border: `1px solid ${theme.palette.primary.main}`,
          padding: "6px",
          mt: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ height: "100%", width: "100%" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={36}
            height={36}
            sx={{
              background: theme.palette.primary.main,
              borderRadius: "50%",
            }}
          >
            <IconButton
              size="small"
              sx={{
                color: theme.palette.primary.contrastText,
                width: "100%",
                height: "100%",
                p: 0,
              }}
              onClick={() => setStrokes((prev) => Math.max(prev - 1, 1))}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography fontFamily="Tiempos Headline" fontSize={24}>
              {strokes}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={36}
            height={36}
            sx={{
              background: theme.palette.primary.main,
              borderRadius: "50%",
            }}
          >
            <IconButton
              size="small"
              sx={{
                color: theme.palette.primary.contrastText,
                width: "100%",
                height: "100%",
                p: 0,
              }}
              onClick={() => setStrokes((prev) => prev + 1)}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      <Typography fontSize={10} sx={{ mt: 2 }}>
        Wolf
      </Typography>
      <Checkbox
        checked={holeWolf}
        onChange={(event) => {
          setHoleWolf(event.target.checked);
        }}
      />
      <Button
        variant="contained"
        disabled={
          submitting ||
          (strokes === score.gross[hole - 1] && holeWolf === wolf[hole - 1])
        }
        sx={{ mt: 2 }}
        onClick={() => Submit()}
      >
        Submit
      </Button>
    </Stack>
  );
}

function WolfRow(user, wolfScore) {
  const logo = <Box component="img" src="wolf.jpg" sx={{ height: "1.3em" }} />;
  return {
    ...wolfScore.wolf.map((x, i) => (
      <>
        {x && wolfScore.team[i].includes(user) && logo}
        {!x && wolfScore.opp[i].includes(user) && logo}
      </>
    )),
  };
}
