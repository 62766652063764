import React from "react";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { BASE_COLUMNS, BASE_SUBCOLUMNS } from "./utils";
import { submitScorecardUpdate } from "../api_utils";
import { Table } from "../components/table";
import {
  useGamesConfigContext,
  useRefreshCallbackContext,
  useScoresContext,
} from "../data_provider";
import { GetHandicap, GetUsername } from "../utils";

export function CustomGame({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();
  const theme = useTheme();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const columns = BASE_COLUMNS;
  const rows = Object.entries(gameScores).map(([user, score]) => {
    const subcolumns = SubColumns();

    const subrows = Object.entries(score.category_points).map(
      ([category, points]) => ({ category: category, total: points })
    );

    return {
      name: `${GetUsername(user, yearGamesConfig)} (${GetHandicap(user, yearGamesConfig)})`,
      total: score.points,
      subComponent: <Table columns={subcolumns} rows={subrows} />,
    };
  });

  return (
    <Paper
      variant="outlined"
      sx={{ borderColor: theme.palette.misc.borderColor }}
    >
      <Box p={2}>
        <Table columns={columns} rows={rows} />
      </Box>
    </Paper>
  );
}

export function CustomGameScorecard({ game, year, userId }) {
  const [counts, setCounts] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const scores = useScoresContext();
  const refreshCallback = useRefreshCallbackContext();
  const theme = useTheme();

  const yearScores = scores[year];

  const score = yearScores.scores[game.game_id][userId];

  useEffect(() => setCounts(score.category_counts), [score]);

  const columns = SubColumns();

  const rows = Object.entries(score.category_points).map(
    ([category, points]) => ({ category: category, total: points })
  );

  const Submit = () => {
    setSubmitting(true);
    return submitScorecardUpdate({
      year: `${year}`,
      game_id: game.game_id,
      custom_game: { counts: { ...counts } },
    })
      .then(() => refreshCallback())
      .then(() => setSubmitting(false))
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <Box p={2}>
          <Table columns={columns} rows={rows} />
        </Box>
      </Paper>
      <Stack alignItems="center">
        {Object.entries(counts || {}).map(([category, count], i) => (
          <React.Fragment key={i}>
            <Typography
              fontSize={10}
              sx={{ textTransform: "capitalize", mt: 2 }}
            >
              {category}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "144px",
                height: "50px",
                borderRadius: "24px",
                border: `1px solid ${theme.palette.primary.main}`,
                padding: "6px",
                mt: 1,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ height: "100%", width: "100%" }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={36}
                  height={36}
                  sx={{
                    background: theme.palette.primary.main,
                    borderRadius: "50%",
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      width: "100%",
                      height: "100%",
                      p: 0,
                    }}
                    onClick={() =>
                      setCounts((prev) => ({
                        ...prev,
                        [category]: Math.max(prev[category] - 1, 0),
                      }))
                    }
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Typography fontFamily="Tiempos Headline" fontSize={18}>
                    {count}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={36}
                  height={36}
                  sx={{
                    background: theme.palette.primary.main,
                    borderRadius: "50%",
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      width: "100%",
                      height: "100%",
                      p: 0,
                    }}
                    onClick={() =>
                      setCounts((prev) => ({
                        ...prev,
                        [category]: prev[category] + 1,
                      }))
                    }
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </React.Fragment>
        ))}
        <Button
          variant="contained"
          disabled={
            submitting ||
            JSON.stringify(counts) === JSON.stringify(score.category_counts)
          }
          sx={{ mt: 2 }}
          onClick={() => Submit()}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}

function SubColumns() {
  return [
    BASE_SUBCOLUMNS.find((x) => x.id === "category"),
    BASE_SUBCOLUMNS.find((x) => x.id === "total"),
  ];
}
