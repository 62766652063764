export const COLORS = {
  primary: {
    light: "rgb(42,84,60)",
    main: "#005e23",
    dark: "rgb(24,50,40)",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#e52534",
    main: "#fcf400",
    dark: "#e52534",
    contrastText: "#000000",
  },
  tertiary: {
    main: "#c9628f",
    contrastText: "#ffffff",
  },
  net_score: {
    double_eagle: "teal",
    eagle: "teal",
    birdie: "crimson",
    bogie: "darkorchid",
    double_bogie: "darkorchid",
    triple_bogie: "orange",
  },
  misc: {
    border: "rgba(0,0,0,0.2)",
  },
  background: {
    default: "#fff",
  },
};
