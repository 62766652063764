import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { BASE_COLUMNS, BASE_SUBCOLUMN_STYLE, BASE_SUBCOLUMNS } from "./utils";
import { submitScorecardUpdate } from "../api_utils";
import { Table } from "../components/table";
import {
  useGamesConfigContext,
  useRefreshCallbackContext,
  useScoresContext,
} from "../data_provider";
import { GetHandicap, GetUsername } from "../utils";

export function Bonus2024({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();
  const theme = useTheme();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const columns = BASE_COLUMNS;
  const rows = Object.entries(gameScores).map(([user, score]) => {
    const subcolumns = SubColumns();

    const subrows = [
      {
        category: "Chip Ins",
        count: score.chip_ins,
        total: score.chip_in_points,
      },
      {
        category: "Hole in Ones",
        count: score.hole_in_ones,
        total: score.hole_in_one_points,
      },
    ];

    return {
      name: `${GetUsername(user, yearGamesConfig)} (${GetHandicap(user, yearGamesConfig)})`,
      total: score.points,
      subComponent: <Table columns={subcolumns} rows={subrows} />,
    };
  });

  return (
    <Paper
      variant="outlined"
      sx={{ borderColor: theme.palette.misc.borderColor }}
    >
      <Box p={2}>
        <Table columns={columns} rows={rows} />
      </Box>
    </Paper>
  );
}

export function Bonus2024Scorecard({ game, year, userId }) {
  const [chipIns, setChipIns] = useState(null);
  const [holeInOnes, setHoleInOnes] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const scores = useScoresContext();
  const refreshCallback = useRefreshCallbackContext();
  const theme = useTheme();

  const yearScores = scores[year];

  const score = yearScores.scores[game.game_id][userId];

  useEffect(() => {
    setChipIns(score.chip_ins);
    setHoleInOnes(score.hole_in_ones);
  }, [score]);

  const columns = SubColumns();
  const rows = [
    {
      category: "Chip Ins",
      count: score.chip_ins,
      total: score.chip_in_points,
    },
    {
      category: "Hole in Ones",
      count: score.hole_in_ones,
      total: score.hole_in_one_points,
    },
  ];

  const Submit = () => {
    setSubmitting(true);
    return submitScorecardUpdate({
      year: `${year}`,
      game_id: game.game_id,
      bonus_2024: { chip_ins: chipIns, hole_in_ones: holeInOnes },
    })
      .then(() => refreshCallback())
      .then(() => setSubmitting(false))
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <Box p={2}>
          <Table columns={columns} rows={rows} />
        </Box>
      </Paper>
      <Stack alignItems="center">
        <Typography fontSize={10} sx={{ mt: 2 }}>
          Chip Ins
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "144px",
            height: "50px",
            borderRadius: "24px",
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "6px",
            mt: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ height: "100%", width: "100%" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.primary.contrastText,
                  width: "100%",
                  height: "100%",
                  p: 0,
                }}
                onClick={() => setChipIns((prev) => Math.max(prev - 1, 0))}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography fontFamily="Tiempos Headline" fontSize={24}>
                {chipIns}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.primary.contrastText,
                  width: "100%",
                  height: "100%",
                  p: 0,
                }}
                onClick={() => setChipIns((prev) => prev + 1)}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Typography fontSize={10} sx={{ mt: 2 }}>
          Hole in Ones
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "144px",
            height: "50px",
            borderRadius: "24px",
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "6px",
            mt: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ height: "100%", width: "100%" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.primary.contrastText,
                  width: "100%",
                  height: "100%",
                  p: 0,
                }}
                onClick={() => setHoleInOnes((prev) => Math.max(prev - 1, 0))}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography fontFamily="Tiempos Headline" fontSize={24}>
                {holeInOnes}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.primary.contrastText,
                  width: "100%",
                  height: "100%",
                  p: 0,
                }}
                onClick={() => setHoleInOnes((prev) => prev + 1)}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Button
          variant="contained"
          disabled={
            submitting ||
            (chipIns === score.chip_ins && holeInOnes === score.hole_in_ones)
          }
          sx={{ mt: 2 }}
          onClick={() => Submit()}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}

function SubColumns() {
  return [
    BASE_SUBCOLUMNS.find((x) => x.id === "category"),
    {
      id: "count",
      label: "Count",
      align: "center",
      sx: {
        ...BASE_SUBCOLUMN_STYLE,
        flexGrow: 0,
      },
    },
    BASE_SUBCOLUMNS.find((x) => x.id === "total"),
  ];
}
