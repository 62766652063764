import React from "react";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { CenteredBox } from "./base";

export function Table({ columns, rows, containerProps, tableProps }) {
  const [state, setState] = React.useState({
    rowIndices: [...Array(rows.length).keys()],
    sortColumn: null,
    sortDirection: null,
  });

  // Sorts rows based on input column
  const handleSortRequest = (column) => {
    let rowIndices = state.rowIndices;
    let sortColumn = state.sortColumn;
    let sortDirection = state.sortDirection;

    if (sortColumn === column.id) {
      sortDirection =
        column.sortable[
          (column.sortable.findIndex((element) => element === sortDirection) +
            1) %
            column.sortable.length
        ];
    } else {
      sortColumn = column.id;
      sortDirection = column.sortable[0];
    }

    if (sortDirection !== null) {
      rowIndices = rowIndices.sort((a, b) => {
        const a_val = rows[a][sortColumn];
        const b_val = rows[b][sortColumn];
        const sign = sortDirection === "asc" ? 1 : -1;
        return a_val < b_val ? sign * -1 : a_val > b_val ? sign * 1 : 0;
      });
    }

    setState((prevState) => ({
      rowIndices: rowIndices,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    }));
  };

  React.useEffect(() => {
    columns.filter((x) => x.sorted).forEach((x) => handleSortRequest(x));
    // eslint-disable-next-line
  }, []);

  const expandable = rows.some((x) => x.subComponent);

  return (
    <TableContainer {...containerProps}>
      <MuiTable {...tableProps}>
        <TableHead>
          <TableRow>
            {expandable && (
              <TableCell
                key="expandarrow"
                sx={{ flexGrow: 0, width: "auto", p: 0 }}
              />
            )}
            {columns.map((x) => (
              <TableCell key={x.id} align={x.align} sx={x.sx}>
                {x.sortable ? (
                  <TableSortLabel
                    active={
                      state.sortColumn === x.id && state.sortDirection !== null
                    }
                    direction={
                      state.sortDirection ? state.sortDirection : "asc"
                    }
                    onClick={(ev) => {
                      handleSortRequest(x);
                    }}
                  >
                    {x.label}
                  </TableSortLabel>
                ) : (
                  x.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.rowIndices.map((x) => (
            <ExpandableRow
              row={rows[x]}
              columns={columns}
              expandable={expandable}
              key={x}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

function ExpandableRow({ row, columns, expandable }) {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  const ArrowCell = () => (
    <TableCell key="expandarrow" sx={{ maxWidth: "1rem", p: 0 }}>
      <CenteredBox
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <IconButton
          size="small"
          onClick={() => setExpanded(!expanded)}
          sx={{ p: 0 }}
        >
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </CenteredBox>
    </TableCell>
  );

  return (
    <React.Fragment>
      <TableRow>
        {expandable && <ArrowCell />}
        {columns.map((column) => (
          <TableCell align={column.align} sx={column.sx} key={column.id}>
            {row?.[column.id]}
          </TableCell>
        ))}
      </TableRow>

      {row.subComponent && (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ p: 0, border: 0 }}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CenteredBox>
                <div
                  style={{
                    marginBottom: `${theme.spacing(4)}`,
                    overflowX: "auto",
                    /* TODO(peter): can "100vw" be the parent width instead somehow? */
                    maxWidth: `calc(100vw - ${theme.spacing(12)}`,
                  }}
                >
                  {row.subComponent}
                </div>
              </CenteredBox>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
