import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  BASE_COLUMNS,
  BASE_SUBCOLUMN_STYLE,
  BASE_SUBCOLUMNS,
  GetCourse,
  Sum,
} from "./utils";
import { submitScorecardUpdate } from "../api_utils";
import { Table } from "../components/table";
import { NetToPar } from "../components/net_to_par";
import { ParWithStrokes } from "../components/par_with_strokes";
import {
  useGamesConfigContext,
  useRefreshCallbackContext,
  useScoresContext,
} from "../data_provider";
import { GetHandicap, GetUsername } from "../utils";

export function Medal({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();
  const theme = useTheme();

  const yearGamesConfig = gamesConfig?.[year] || {};
  const yearScores = scores?.[year] || {};

  const { course, tees } = GetCourse(
    game.medal.config.course,
    game.medal.config.tees,
    yearGamesConfig.courses
  );
  const gameScores = yearScores?.scores?.[game.game_id] || {};

  const columns = BASE_COLUMNS;
  const rows = Object.entries(gameScores).map(([user, score]) => {
    const subcolumns = SubColumns(course, tees);

    const subrows = [
      IndexRow(score),
      ParRow(score),
      GrossRow(score),
      NetRow(score),
      PointsRow(score),
    ];

    return {
      name: `${GetUsername(user, yearGamesConfig)} (${GetHandicap(user, yearGamesConfig)})`,
      total: score.points,
      subComponent: <Table columns={subcolumns} rows={subrows} />,
    };
  });

  return (
    <Paper
      variant="outlined"
      sx={{ borderColor: theme.palette.misc.borderColor }}
    >
      <Box p={2}>
        <Table columns={columns} rows={rows} />
      </Box>
    </Paper>
  );
}

export function MedalScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();
  const theme = useTheme();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];

  const { course, tees } = GetCourse(
    game.medal.config.course,
    game.medal.config.tees,
    yearGamesConfig.courses
  );
  const score = yearScores.scores[game.game_id][userId];

  const columns = SubColumns(course, tees);

  const rows = [
    IndexRow(score),
    ParRow(score),
    GrossRow(score),
    NetRow(score),
    PointsRow(score),
  ];

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <Paper
        variant="outlined"
        sx={{ borderColor: theme.palette.misc.borderColor }}
      >
        <Box p={2}>
          <Table columns={columns} rows={rows} />
        </Box>
      </Paper>
      <MedalHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={score}
      />
    </Stack>
  );
}

export function MedalHoleScorecard({ gameId, year, course, score }) {
  const [hole, setHole] = useState(null);
  const [strokes, setStrokes] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const refreshCallback = useRefreshCallbackContext();
  const theme = useTheme();

  useEffect(() => {
    setStrokes(score.gross[hole - 1]);
  }, [hole, score]);

  if (!hole) setHole(1);

  const Submit = () => {
    setSubmitting(true);
    return submitScorecardUpdate({
      year: `${year}`,
      game_id: gameId,
      medal: { hole: hole - 1, strokes: strokes },
    })
      .then(() => refreshCallback())
      .then(() => setSubmitting(false))
      .catch((err) => {
        setError(err);
        setSubmitting(false);
      });
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <Stack direction="row" justifyContent="center">
        <Stack direction="row" alignItems="center">
          <IconButton
            size="large"
            onClick={() => setHole((prev) => Math.max(prev - 1, 1))}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <Typography fontFamily="Tiempos Headline" fontSize={24}>
            Hole {hole}
          </Typography>
          <IconButton
            size="large"
            onClick={() =>
              setHole((prev) => Math.min(prev + 1, course.hole_par.length))
            }
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Stack>
      <Stack alignItems="center">
        <Typography fontSize={10} sx={{ pb: 1 }}>
          Strokes
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "144px",
            height: "50px",
            borderRadius: "24px",
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "6px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ height: "100%", width: "100%" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.primary.contrastText,
                  width: "100%",
                  height: "100%",
                  p: 0,
                }}
                onClick={() => setStrokes((prev) => Math.max(prev - 1, 1))}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography fontFamily="Tiempos Headline" fontSize={24}>
                {strokes}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.primary.contrastText,
                  width: "100%",
                  height: "100%",
                  p: 0,
                }}
                onClick={() => setStrokes((prev) => prev + 1)}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Button
          variant="contained"
          disabled={submitting || strokes === score.gross[hole - 1]}
          sx={{ mt: 2 }}
          onClick={() => Submit()}
        >
          Submit
        </Button>
      </Stack>
      {error && (
        <Snackbar open={!!error} onClose={() => setError(null)}>
          <Alert severity="warning" sx={{ width: "100vw", mb: 7 }}>
            Submit failed, try again
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
}

export function SubColumns(course, tees) {
  return [...BASE_SUBCOLUMNS, ...PerHoleColumns(course, tees)];
}

export function PerHoleColumns(course, tees) {
  return [
    ...Array.from(Array(course.hole_par.length).keys(), (x) => ({
      id: x,
      label: `${x + 1}`,
      align: "center",
      sx: {
        ...BASE_SUBCOLUMN_STYLE,
        flexGrow: 0,
        width: "auto",
      },
    })),
  ];
}

export function IndexRow(score) {
  return { category: "Index", total: null, ...score?.index };
}

export function ParRow(score) {
  return {
    category: "Par",
    total: Sum(score?.par),
    ...Array.from(Array(score?.par?.length).keys(), (i) => (
      <ParWithStrokes strokes={score?.strokes_given?.[i]}>
        {score?.par?.[i]}
      </ParWithStrokes>
    )),
  };
}

export function GrossRow(score) {
  return {
    category: "Gross",
    total: Sum(score?.gross),
    ...score?.gross,
  };
}

export function NetRow(score) {
  return {
    category: "Net",
    total: Sum(score?.net),
    ...Array.from(Array(score?.net.length).keys(), (i) => (
      <NetToPar toPar={score?.net?.[i] ? score?.net?.[i] - score?.par?.[i] : 0}>
        {score?.net?.[i]}
      </NetToPar>
    )),
  };
}

export function PointsRow(score) {
  return {
    category: "Points",
    total: Sum(score?.hole_points),
    ...score?.hole_points,
  };
}
