import React from "react";
import GoogleIcon from "@mui/icons-material/Google";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { login } from "../auth_utils";
import { useGamesConfigContext } from "../data_provider";
import { currentRound, currentYear, isTournamentActive } from "../env_utils";
import { ErrorAlert } from "../error_alert";
import { useErrorContext } from "../error_provider";
import { BestBallScorecard } from "../games/best_ball";
import { BestBallVersusScorecard } from "../games/best_ball_versus";
import { Bonus2024Scorecard } from "../games/bonus_2024";
import { CustomGameScorecard } from "../games/custom_game";
import { MedalScorecard } from "../games/medal";
import { MedalVersusScorecard } from "../games/medal_versus";
import { isRyderCupStyle, RyderCupScore } from "../games/ryder_cup";
import { ScrambleScorecard } from "../games/scramble";
import { ScrambleVersusScorecard } from "../games/scramble_versus";
import { WolfScorecard } from "../games/wolf";
import { Loading } from "../loading";

export default function Scores() {
  const [user, setUser] = React.useState(null);
  const [game, setGame] = React.useState(null);
  const { error } = useErrorContext();
  const gamesConfig = useGamesConfigContext();

  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => setUser(user));
    return () => unsubscribe();
  }, []);

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (!user) {
    return <SignInPage />;
  }

  if (!gamesConfig) {
    return <Loading />;
  }

  const year = currentYear();
  const yearGamesConfig = gamesConfig[year];
  if (!yearGamesConfig || !isTournamentActive()) {
    return (
      <Box m={2}>
        <Alert severity="info">
          <AlertTitle>{"Tournament closed"}</AlertTitle>
          Check back later
        </Alert>
      </Box>
    );
  }

  if (!Object.keys(yearGamesConfig.users).includes(user.email)) {
    return (
      <Box m={2}>
        <Alert
          severity="info"
          onClose={() =>
            getAuth()
              .signOut()
              .catch((err) => console.error(err))
          }
        >
          <AlertTitle>{`Not authorized`}</AlertTitle>
          {`${user.email} not in list of users, contact Petey`}
        </Alert>
      </Box>
    );
  }

  const roundList = yearGamesConfig.games.map((game) => ({
    value: game.game_id,
    label: game.name,
  }));

  if (game === null) {
    setGame(currentRound());
    return <Loading />;
  }

  return (
    <Box m={2}>
      <Stack justifyContent="space-between" spacing={2}>
        {isRyderCupStyle(yearGamesConfig) && <RyderCupScore year={year} />}
        <PageSelect
          label="Round"
          page={game}
          pageList={roundList}
          onChange={(event) => setGame(event.target.value)}
        />
        {gamesConfig[year].games.map(
          (x) =>
            game === x.game_id && (
              <GamePage game={x} userId={user.email} year={year} key={game} />
            )
        )}
        <Box display="flex" justifyContent="center" alignItems="center" pt={8}>
          <Button
            variant="contained"
            startIcon=<LogoutIcon />
            onClick={() =>
              getAuth()
                .signOut()
                .catch((err) => console.error(err))
            }
          >
            Sign out
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

function SignInPage() {
  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState(null);

  const tryLogin = () => {
    setError(null);
    setPending(true);
    login()
      .catch((err) => {
        setError({
          title: "Login Failed",
          msg: "Failed to log in, try again.",
        });
        console.error(err, err.stack);
      })
      .then(setPending(false));
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="center" alignItems="center" m={2}>
        <Button variant="contained" startIcon=<GoogleIcon /> onClick={tryLogin}>
          Sign in with Google
        </Button>
      </Box>
      {error && (
        <Box width="100%" sx={{ position: "fixed", bottom: "56px" }}>
          <ErrorAlert error={error} onClose={() => setError(null)} />
        </Box>
      )}
    </React.Fragment>
  );
}

function GamePage({ game, userId, year }) {
  if ("medal" in game) {
    return <MedalScorecard game={game} year={year} userId={userId} />;
  } else if ("scramble" in game) {
    return <ScrambleScorecard game={game} year={year} userId={userId} />;
  } else if ("best_ball" in game) {
    return <BestBallScorecard game={game} year={year} userId={userId} />;
  } else if ("medal_versus" in game) {
    return <MedalVersusScorecard game={game} year={year} userId={userId} />;
  } else if ("scramble_versus" in game) {
    return <ScrambleVersusScorecard game={game} year={year} userId={userId} />;
  } else if ("best_ball_versus" in game) {
    return <BestBallVersusScorecard game={game} year={year} userId={userId} />;
  } else if ("wolf" in game) {
    return <WolfScorecard game={game} year={year} userId={userId} />;
  } else if ("bonus_2024" in game) {
    return <Bonus2024Scorecard game={game} year={year} userId={userId} />;
  } else if ("custom_game" in game) {
    return <CustomGameScorecard game={game} year={year} userId={userId} />;
  }

  return <></>;
}

function PageSelect({ label, page, pageList, onChange, formControlProps }) {
  return (
    <FormControl fullWidth {...formControlProps}>
      <InputLabel>{label}</InputLabel>
      <Select value={page} label={label} onChange={onChange}>
        {pageList.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ p: 2 }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
