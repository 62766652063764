import { Box, Stack } from "@mui/material";

import { VersusScorecard } from "../components/group_games";
import { Table } from "../components/table";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { GetUsername } from "../utils";
import {
  IndexRow,
  GrossRow,
  MedalHoleScorecard,
  NetRow,
  ParRow,
  PointsRow,
  SubColumns,
} from "./medal";
import { GetCourse } from "./utils";

export function ScrambleVersus({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig?.[year] || {};
  const yearScores = scores?.[year] || {};
  const gameScores = yearScores?.scores?.[game.game_id] || {};

  const { course, tees } = GetCourse(
    game.scramble_versus.config.medal_config.course,
    game.scramble_versus.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const groups = game.scramble_versus.groups.filter((group) =>
    group.teams.every((team) => team.members.length)
  );

  return (
    <Stack justifyContent="space-between" spacing={2}>
      {groups.map((group, i) => {
        const usersLeft = group.teams[0].members;
        const usersRight = group.teams[1].members;

        const Names = (users) =>
          users.map((user) => GetUsername(user, yearGamesConfig));
        const Score = (users) =>
          gameScores[
            users
              .sort(
                (a, b) =>
                  gameScores[a].score.gross.filter((x) => !!x).length -
                  gameScores[b].score.gross.filter((x) => !!x).length
              )
              .reverse()[0]
          ];

        const scoreLeft = Score(usersLeft);
        const scoreRight = Score(usersRight);

        const columns = SubColumns(course, tees);
        const rows = [
          IndexRow(scoreLeft.score),
          {},
          ParRow(scoreLeft.score),
          GrossRow(scoreLeft.score),
          NetRow(scoreLeft.score),
          PointsRow(scoreLeft.score),
          {},
          ParRow(scoreRight.score),
          GrossRow(scoreRight.score),
          NetRow(scoreRight.score),
          PointsRow(scoreRight.score),
        ];

        const subcomponent = (
          <Box display="flex" alignItems="center" justifyContent="center" p={2}>
            <Table columns={columns} rows={rows} />
          </Box>
        );

        return (
          <VersusScorecard
            variant={game.scramble_versus.config.versus_mode}
            playersLeft={Names(usersLeft)}
            playersRight={Names(usersRight)}
            progress={Math.min(
              scoreLeft.score.gross.filter((x) => x).length,
              scoreRight.score.gross.filter((x) => x).length
            )}
            holePointsLeft={scoreLeft.hole_points.map((x, i) =>
              scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
            )}
            holePointsRight={scoreRight.hole_points.map((x, i) =>
              scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
            )}
            course={course}
            subcomponent={subcomponent}
            key={i}
          />
        );
      })}
    </Stack>
  );
}

export function ScrambleVersusScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.scramble_versus.config.medal_config.course,
    game.scramble_versus.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const group =
    game.scramble_versus.groups.find((group) =>
      group.teams.find((team) => team.members.includes(userId))
    ) || {};
  const score = gameScores[userId];

  const usersLeft = group.teams[0].members;
  const usersRight = group.teams[1].members;

  const Names = (users) =>
    users.map((user) => GetUsername(user, yearGamesConfig));
  const Score = (users) =>
    gameScores[
      users
        .sort(
          (a, b) =>
            gameScores[a].score.gross.filter((x) => !!x).length -
            gameScores[b].score.gross.filter((x) => !!x).length
        )
        .reverse()[0]
    ];

  const scoreLeft = Score(usersLeft);
  const scoreRight = Score(usersRight);

  const columns = SubColumns(course, tees);
  const rows = [
    IndexRow(scoreLeft.score),
    {},
    ParRow(scoreLeft.score),
    GrossRow(scoreLeft.score),
    NetRow(scoreLeft.score),
    PointsRow(scoreLeft.score),
    {},
    ParRow(scoreRight.score),
    GrossRow(scoreRight.score),
    NetRow(scoreRight.score),
    PointsRow(scoreRight.score),
  ];

  const subcomponent = (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <Table columns={columns} rows={rows} />
    </Box>
  );

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <VersusScorecard
        variant={game.scramble_versus.config.versus_mode}
        playersLeft={Names(usersLeft)}
        playersRight={Names(usersRight)}
        progress={Math.min(
          scoreLeft.score.gross.filter((x) => x).length,
          scoreRight.score.gross.filter((x) => x).length
        )}
        holePointsLeft={scoreLeft.hole_points.map((x, i) =>
          scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
        )}
        holePointsRight={scoreRight.hole_points.map((x, i) =>
          scoreLeft.score.gross[i] && scoreRight.score.gross[i] ? x : null
        )}
        course={course}
        subcomponent={subcomponent}
      />
      <MedalHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={score.score}
      />
    </Stack>
  );
}
