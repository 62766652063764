import PullToRefresh from "pulltorefreshjs";
import React from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import { getData } from "./api_utils";
import { COLORS } from "./colors";
import { DataProvider } from "./data_provider";
import { ErrorProvider } from "./error_provider";
import MobileApp from "./mobile_app";

export default function App() {
  const theme = createTheme({
    palette: COLORS,
    typography: {
      fontFamily: "Tiempos",
      fontSize: 14,
      matchProgress: {
        fontSize: 10,
        fontStyle: "italic",
        lineHeight: 1,
      },
      matchScore: {
        fontSize: 28,
        fontFamily: "Tiempos Headline",
        fontWeight: "bold",
      },
      matchScoreSecondary: {
        fontSize: 12,
        fontFamily: "Tiempos Headline",
        fontWeight: "bold",
      },
      matchScoreTied: {
        fontSize: 18,
        fontFamily: "Tiempos Headline",
        fontWeight: "bold",
      },
      teamBox: {
        fontSize: 12,
        textTransform: "capitalize",
      },
      ryderCupTeamName: {
        fontFamily: "Tiempos Headline",
        fontWeight: "regular",
        width: "50%",
        fontSize: "1rem",
        lineHeight: "1rem",
      },
      ryderCupScore: {
        fontFamily: "Tiempos Headline",
        fontWeight: "bold",
        fontSize: "2rem",
        lineHeight: "2rem",
      },
    },
  });

  PullToRefresh.init({
    onRefresh() {
      window.location.reload();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorProvider>
        <DataProvider>
          <MobileApp />
        </DataProvider>
      </ErrorProvider>
    </ThemeProvider>
  );
}
