import { Box, Stack } from "@mui/material";

import { VersusScorecard } from "../components/group_games";
import { Table } from "../components/table";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { GetUsername } from "../utils";
import {
  IndexRow,
  GrossRow,
  MedalHoleScorecard,
  NetRow,
  ParRow,
  PointsRow,
  SubColumns,
} from "./medal";
import { GetCourse } from "./utils";

export function BestBallVersus({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig?.[year] || {};
  const yearScores = scores?.[year] || {};
  const gameScores = yearScores?.scores?.[game.game_id] || {};

  const { course, tees } = GetCourse(
    game.best_ball_versus.config.medal_config.course,
    game.best_ball_versus.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const groups = game.best_ball_versus.groups.filter((group) =>
    group.teams.every((team) => team.members.length)
  );

  return (
    <Stack justifyContent="space-between" spacing={2}>
      {groups.map((group, i) => {
        const usersLeft = group.teams[0].members;
        const usersRight = group.teams[1].members;

        const Names = (users) =>
          users.map((user) => GetUsername(user, yearGamesConfig));

        const Score = (users) =>
          gameScores[
            users
              .sort(
                (a, b) =>
                  gameScores[a].combined.gross.filter((x) => !!x).length -
                  gameScores[b].combined.gross.filter((x) => !!x).length
              )
              .reverse()[0]
          ];

        const scoreLeft = Score(usersLeft);
        const scoreRight = Score(usersRight);

        const columns = SubColumns(course, tees);
        const rows = [
          IndexRow(scoreLeft.combined),
          ParRow(
            Object.fromEntries(
              Object.entries(scoreLeft.combined).filter(
                ([a, b]) => a !== "strokes_given"
              )
            )
          ),
          ...Object.entries(scoreLeft.team)
            .map(([u, x]) => [
              {
                ...GrossRow(x),
                category: `${GetUsername(u, yearGamesConfig)} Gross`,
              },
              {
                ...NetRow(x),
                category: `${GetUsername(u, yearGamesConfig)} Net`,
              },
            ])
            .flat(),
          { ...NetRow(scoreLeft.combined), category: "Combined Net" },
          {},
          ...Object.entries(scoreRight.team)
            .map(([u, x]) => [
              {
                ...GrossRow(x),
                category: `${GetUsername(u, yearGamesConfig)} Gross`,
              },
              {
                ...NetRow(x),
                category: `${GetUsername(u, yearGamesConfig)} Net`,
              },
            ])
            .flat(),
          { ...NetRow(scoreRight.combined), category: "Combined Net" },
        ];

        const subcomponent = (
          <Box display="flex" alignItems="center" justifyContent="center" p={2}>
            <Table columns={columns} rows={rows} />
          </Box>
        );

        return (
          <VersusScorecard
            variant={game.best_ball_versus.config.versus_mode}
            playersLeft={Names(usersLeft)}
            playersRight={Names(usersRight)}
            progress={Math.min(
              scoreLeft.combined.gross.filter((x) => x).length,
              scoreRight.combined.gross.filter((x) => x).length
            )}
            holePointsLeft={scoreLeft.hole_points.map((x, i) =>
              scoreLeft.combined.gross[i] && scoreRight.combined.gross[i]
                ? x
                : null
            )}
            holePointsRight={scoreRight.hole_points.map((x, i) =>
              scoreLeft.combined.gross[i] && scoreRight.combined.gross[i]
                ? x
                : null
            )}
            course={course}
            subcomponent={subcomponent}
            key={i}
          />
        );
      })}
    </Stack>
  );
}

export function BestBallVersusScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.best_ball_versus.config.medal_config.course,
    game.best_ball_versus.config.medal_config.tees,
    yearGamesConfig.courses
  );

  const group =
    game.best_ball_versus.groups.find((group) =>
      group.teams.find((team) => team.members.includes(userId))
    ) || {};
  const score = gameScores[userId];

  const usersLeft = group.teams[0].members;
  const usersRight = group.teams[1].members;

  const Names = (users) =>
    users.map((user) => GetUsername(user, yearGamesConfig));
  const Score = (users) =>
    gameScores[
      users
        .sort(
          (a, b) =>
            gameScores[a].combined.gross.filter((x) => !!x).length -
            gameScores[b].combined.gross.filter((x) => !!x).length
        )
        .reverse()[0]
    ];

  const scoreLeft = Score(usersLeft);
  const scoreRight = Score(usersRight);

  const columns = SubColumns(course, tees);
  const rows = [
    IndexRow(score.team[userId]),
    ParRow(score.team[userId]),
    GrossRow(score.team[userId]),
    NetRow(score.team[userId]),
    { ...NetRow(score.combined), category: "Team Net" },
    { ...NetRow(score.opp_combined), category: "Opp Net" },
  ];

  const subcomponent = (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <Table columns={columns} rows={rows} />
    </Box>
  );

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <VersusScorecard
        variant={game.best_ball_versus.config.versus_mode}
        playersLeft={Names(usersLeft)}
        playersRight={Names(usersRight)}
        progress={Math.min(
          scoreLeft.combined.gross.filter((x) => x).length,
          scoreRight.combined.gross.filter((x) => x).length
        )}
        holePointsLeft={scoreLeft.hole_points.map((x, i) =>
          scoreLeft.combined.gross[i] && scoreRight.combined.gross[i] ? x : null
        )}
        holePointsRight={scoreRight.hole_points.map((x, i) =>
          scoreLeft.combined.gross[i] && scoreRight.combined.gross[i] ? x : null
        )}
        course={course}
        subcomponent={subcomponent}
      />
      <MedalHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={score?.team?.[userId]}
      />
    </Stack>
  );
}
