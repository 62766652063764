import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { createTheme } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Sum } from "../games/utils";

export function CoopScorecard({
  side,
  players,
  progress,
  points,
  hole_points,
  course,
  subcomponent,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  return (
    <Paper square elevation={3}>
      <Grid container>
        {/* Team box */}
        <Grid item xs={4}>
          {side === "left" && (
            <Box display="flex" justifyContent="left">
              <TeamBox
                side="left"
                color={theme.palette.primary.main}
                textColor={theme.palette.primary.contrastText}
                players={players}
                filled={true}
              />
            </Box>
          )}
          {side === "right" && (
            <Box
              display="flex"
              justifyContent="left"
              alignItems="center"
              width="100%"
              height="100%"
              p={2}
            >
              <Typography
                variant="matchScore"
                color={theme.palette.tertiary.main}
                align="left"
              >
                {points}
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Typography variant="matchProgress">
              {ProgressStr(progress, course.hole_par.length)}
            </Typography>
          </Box>
        </Grid>

        {/* Score */}
        <Grid item xs={4}>
          {side === "right" && (
            <Box display="flex" justifyContent="left">
              <TeamBox
                side={side}
                color={theme.palette.tertiary.main}
                textColor={theme.palette.tertiary.contrastText}
                players={players}
                filled={true}
              />
            </Box>
          )}
          {side === "left" && (
            <Box
              display="flex"
              justifyContent="right"
              alignItems="center"
              width="100%"
              height="100%"
              p={2}
            >
              <Typography
                variant="matchScore"
                color={theme.palette.primary.main}
                align="right"
              >
                {points}
              </Typography>
            </Box>
          )}
        </Grid>

        {/* Expand button */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <IconButton size="small" onClick={() => setExpanded(!expanded)}>
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Box>
        </Grid>

        {/* Subcomponent */}
        <Grid item xs={12}>
          <Collapse in={expanded}>{subcomponent}</Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
}

export function VersusScorecard({
  variant = "STROKE",
  playersLeft,
  playersRight,
  progress,
  holePointsLeft,
  holePointsRight,
  course,
  subcomponent,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const theme = useTheme();

  const score = Sum(holePointsLeft) - Sum(holePointsRight);
  const holeScores = [...Array(course.hole_par.length).keys()].map((hole) => {
    if (hole >= holePointsLeft.length) return null;
    if (hole >= holePointsRight.length) return null;
    if (holePointsLeft?.[hole] === null) return null;
    if (holePointsRight?.[hole] === null) return null;
    return holePointsLeft?.[hole] - holePointsRight?.[hole];
  });

  const StyledTeamBox = ({ players, side, colors }) => (
    <TeamBox
      players={players}
      side={side}
      color={colors?.main || ""}
      textColor={colors?.contrastText || ""}
    />
  );
  const LeftTeamBox = ({ players, filled }) => (
    <StyledTeamBox
      players={players}
      side="left"
      colors={filled ? theme.palette.primary : {}}
    />
  );
  const RightTeamBox = ({ players, filled }) => (
    <StyledTeamBox
      players={players}
      side="right"
      colors={filled ? theme.palette.tertiary : {}}
    />
  );

  const ScoreColor = () => {
    if (score > 0) {
      return theme.palette.primary.main;
    } else if (score < 0) {
      return theme.palette.tertiary.main;
    }

    return "gray";
  };

  const ScoreStr = () => {
    if (score === 0) {
      return "TIE";
    }
    return `${Sum(holePointsLeft)} - ${Sum(holePointsRight)}`;
  };

  return (
    <Paper square elevation={3}>
      <Grid container>
        {/* Left players's box */}
        <Grid item xs={4}>
          <Box display="flex" justifyContent="left">
            <LeftTeamBox players={playersLeft} filled={score > 0} />
          </Box>
        </Grid>

        {/* Match progress and score */}
        <Grid item xs={4}>
          <Grid container direction="column" height="100%">
            {/* Match progress */}
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography variant="matchProgress" pt={1}>
                  {ProgressStr(progress, course.hole_par.length)}
                </Typography>
              </Box>
            </Grid>

            {/* Match score */}
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Grid container row="100%">
                  <Grid item xs={5}>
                    <Box
                      display="flex"
                      justifyContent="right"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <Typography
                        variant="matchScore"
                        color={theme.palette.primary.main}
                      >
                        {Sum(holePointsLeft)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <Typography
                        variant="matchScore"
                        color={theme.palette.primary.main}
                      >
                        -
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box
                      display="flex"
                      justifyContent="left"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <Typography
                        variant="matchScore"
                        color={theme.palette.tertiary.main}
                      >
                        {Sum(holePointsRight)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Right players's box */}
        <Grid item xs={4}>
          <Box display="flex" justifyContent="right">
            <RightTeamBox players={playersRight} filled={score < 0} />
          </Box>
        </Grid>

        {/* Per-hole results */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" pt={2} pb={2}>
            <HoleGrid
              holeScores={holeScores}
              progress={progress}
              variant={variant}
            />
          </Box>
        </Grid>

        {/* Expand button */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <IconButton size="small" onClick={() => setExpanded(!expanded)}>
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Box>
        </Grid>

        {/* Subcomponent */}
        <Grid item xs={12}>
          <Collapse in={expanded}>{subcomponent}</Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
}

export function TeamBox({
  players,
  side,
  color,
  textColor,
  width = "7.5rem",
  height = "6rem",
}) {
  const variant = "teamBox";
  if (!["left", "right"].includes(side)) {
    console.warn(`Unrecognized side: ${side}`);
  }
  const textAlign = side;

  return (
    <TeamBackground side={side} color={color} width={width} height={height}>
      <Stack justifyContent="center" sx={{ flexGrow: 1 }}>
        {players?.map((player, i) => {
          const [first, ...rest] = player.split(" ");
          const last = rest.join(" ");
          return (
            <Typography
              variant={variant}
              align={textAlign}
              color={textColor}
              key={i}
            >
              {first} <strong>{last}</strong>
            </Typography>
          );
        })}
      </Stack>
    </TeamBackground>
  );
}

export function TeamBackground({ side, color, width, height, children }) {
  const widthRatio = "20%";
  const ClipPath = (side) => {
    if (side === "left") {
      return `polygon(0% 0%, calc(100% - ${widthRatio}) 0%, 100% 50%, calc(100% - ${widthRatio}) 100%, 0% 100%)`;
    }
    if (side === "right") {
      return `polygon(100% 0%, ${widthRatio} 0%, 0% 50%, ${widthRatio} 100%, 100% 100%)`;
    }

    console.warn(`Unrecognized side: ${side}`);
    return "";
  };

  return (
    <Box
      display="flex"
      sx={{
        width: width,
        height: height,
        background: color,
        clipPath: ClipPath(side),
        p: 1,
      }}
    >
      {children}
    </Box>
  );
}

export function HoleGrid({ holeScores, progress, variant }) {
  const theme = createTheme(useTheme(), {
    typography: {
      circle: {
        fontSize: 10,
      },
    },
  });

  const StyledCircle = (props) => (
    <Circle size="1.6em" fontVariant="circle" {...props} />
  );

  const Color = (score) => {
    if (score > 0) {
      return theme.palette.primary.main;
    }
    if (score < 0) {
      return theme.palette.tertiary.main;
    }
    if (score === 0) {
      return "lightgray";
    }
    return "";
  };

  const TextColor = (score) => {
    if (score > 0) {
      return theme.palette.primary.contrastText;
    }
    if (score < 0) {
      return theme.palette.tertiary.contrastText;
    }

    return "black";
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row">
        {variant === "MATCH" &&
          [...Array(holeScores.length).keys()].map((hole) => (
            <StyledCircle
              label={hole + 1}
              color={Color(holeScores?.[hole])}
              textColor={TextColor(holeScores?.[hole])}
              key={hole}
            />
          ))}
        {variant === "STROKE" &&
          [...Array(holeScores.length).keys()].map((hole) => (
            <Stack key={hole}>
              <StyledCircle label={hole + 1} />
              {hole < progress && (
                <StyledCircle
                  label={holeScores?.[hole] && Math.abs(holeScores[hole])}
                  color={Color(holeScores?.[hole])}
                  textColor={TextColor(holeScores?.[hole])}
                />
              )}
            </Stack>
          ))}
      </Stack>
    </ThemeProvider>
  );
}

function Circle({
  label,
  size,
  color,
  fontVariant,
  textColor,
  marginRatio = 0.05,
}) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize={useTheme().typography?.[fontVariant]?.fontSize}
      width={size}
      height={size}
      borderRadius="50%"
      backgroundColor={color}
      m={`calc(${size} * ${marginRatio})`}
    >
      <Typography variant={fontVariant} color={textColor}>
        {label}
      </Typography>
    </Box>
  );
}

function ProgressStr(progress, holes) {
  if (progress === holes) {
    return "Final";
  }

  return `Thru ${progress}`;
}
