import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { Sum } from "./utils";

export function isRyderCupStyle(yearGamesConfig) {
  return !!yearGamesConfig?.teams;
}

export function RyderCupScore({ year }) {
  const theme = useTheme();
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const teamData = GetRyderCupScores(yearGamesConfig, yearScores).sort();

  const TeamBackground = styled(Box, {
    shouldForwardProp: (prop) =>
      !["roundedLeft", "roundedRight"].includes(prop),
  })(({ roundedLeft, roundedRight, theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: `calc(50% - ${theme.spacing(1)})`,
    borderTopLeftRadius: roundedLeft && theme.spacing(0.5),
    borderBottomLeftRadius: roundedLeft && theme.spacing(0.5),
    borderTopRightRadius: roundedRight && theme.spacing(0.5),
    borderBottomRightRadius: roundedRight && theme.spacing(0.5),
    padding: theme.spacing(2),
  }));

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <TeamBackground roundedLeft backgroundColor={theme.palette.primary.main}>
        <Typography
          variant="ryderCupTeamName"
          align="left"
          color={theme.palette.primary.contrastText}
        >
          {teamData[0].name}
        </Typography>
        <Typography
          variant="ryderCupScore"
          color={theme.palette.primary.contrastText}
        >
          {teamData[0].score}
        </Typography>
      </TeamBackground>
      <TeamBackground
        roundedRight
        backgroundColor={theme.palette.tertiary.main}
      >
        <Typography
          variant="ryderCupScore"
          color={theme.palette.tertiary.contrastText}
        >
          {teamData[1].score}
        </Typography>
        <Typography
          variant="ryderCupTeamName"
          align="right"
          color={theme.palette.tertiary.contrastText}
        >
          {teamData[1].name}
        </Typography>
      </TeamBackground>
    </Box>
  );
}

function GetRyderCupScores(yearGamesConfig, yearScores) {
  const teamScores = {};
  Object.keys(yearScores.team_scores).forEach((gameId) =>
    Object.keys(yearScores.team_scores[gameId]).forEach((team) => {
      if (!(team in teamScores)) {
        teamScores[team] = {};
      }
      teamScores[team][gameId] = yearScores.team_scores[gameId][team];
    })
  );

  return Object.keys(yearGamesConfig.teams).map((team) => ({
    name: team,
    score: Sum(Object.values(teamScores[team])),
  }));
}
