import React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HomeIcon from "@mui/icons-material/Home";
import InsightsIcon from "@mui/icons-material/Insights";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

import { useRefreshCallbackContext } from "./data_provider";
import Home from "./pages/home";
import Leaderboard from "./pages/leaderboard";
import Scores from "./pages/scores";
import Stats from "./pages/stats";

const toolbarHeight = 80;
const bottomNavBottomPadding = IsStandalone() ? 4 : 0;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: "fixed",
  height: toolbarHeight,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-end",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  height: toolbarHeight,
  background: "rgba(0,0,0,0)",
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const BottomNavContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.primary.main,
  paddingBottom: theme.spacing(bottomNavBottomPadding),
}));

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  background: theme.palette.primary.main,
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    color: theme.palette.primary.contrastText,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  })
);

export default function MobileApp() {
  const [page, setPage] = useState("leaderboard");
  const refreshCallback = useRefreshCallbackContext();
  const theme = useTheme();

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      refreshCallback();
    }, 10000);

    return () => clearInterval(interval);
  }, [refreshCallback]);
  */

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      <StyledAppBar elevation={0}>
        <StyledToolbar>
          <Box component="img" src="masters/logo.png" height={48} mb={0.5} />
          <Typography
            fontSize={24}
            fontFamily="Azalea"
            lineHeight={1}
            color={theme.palette.secondary.main}
          >
            Ball Shaggers
          </Typography>
        </StyledToolbar>
      </StyledAppBar>
      <Box
        pb={theme.spacing(
          5 /* height of bottom nav */ +
            bottomNavBottomPadding /* bottom padding */ +
            2 /* padding */
        )}
        sx={{ width: "min(800px, 100vw)" }}
      >
        <StyledToolbar />
        <div hidden={page !== "home"}>
          <Home />
        </div>
        <div hidden={page !== "leaderboard"}>
          <Leaderboard />
        </div>
        <div hidden={page !== "stats"}>
          <Stats />
        </div>
        <div hidden={page !== "scores"}>
          <Scores />
        </div>
      </Box>
      <BottomNavContainer>
        <StyledBottomNavigation
          value={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        >
          <StyledBottomNavigationAction value="home" icon={<HomeIcon />} />
          <StyledBottomNavigationAction
            value="leaderboard"
            icon={<LeaderboardIcon />}
          />
          <StyledBottomNavigationAction value="stats" icon={<InsightsIcon />} />
          <StyledBottomNavigationAction
            value="scores"
            icon={<EditNoteIcon />}
          />
        </StyledBottomNavigation>
      </BottomNavContainer>
    </Box>
  );
}

function IsStandalone() {
  return window.matchMedia("(display-mode: standalone)").matches;
}
