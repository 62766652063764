export function currentYear() {
  return "2025";
  // const result = process.env.REACT_APP_CURRENT_YEAR;
  // if (result === undefined) {
  //   throw new Error("REACT_APP_CURRENT_YEAR must be set");
  // }

  // return result;
}

export function currentRound() {
  return "palm_desert_resort_stableford";
}

export function isTournamentActive() {
  return false;
  // const result = getBooleanEnv("REACT_APP_TOURNAMENT_ACTIVE");

  // if (result === undefined) {
  //   throw new Error("REACT_APP_TOURNAMENT_ACTIVE must be set");
  // }

  // return result;
}

function getBooleanEnv(handle) {
  const result = process.env?.[handle]?.toLowerCase();

  if (result === undefined) return result;

  if (!["true", "false"].includes(result)) {
    throw new Error(
      `env ${handle} must be one of [true, false] (got ${result})`
    );
  }

  return result === "true";
}
