import { Box, Stack } from "@mui/material";

import { isRyderCupStyle } from "./ryder_cup";
import { CoopScorecard } from "../components/group_games";
import { Table } from "../components/table";
import { useGamesConfigContext, useScoresContext } from "../data_provider";
import { GetUsername } from "../utils";
import {
  GrossRow,
  IndexRow,
  MedalHoleScorecard,
  NetRow,
  ParRow,
  PointsRow,
  SubColumns,
} from "./medal";
import { GetCourse } from "./utils";

export function BestBall({ game, year }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.best_ball.config.course,
    game.best_ball.config.tees,
    yearGamesConfig.courses
  );

  const teamScores = game.best_ball.teams
    .filter((team) => team.members.length)
    .map((team) => ({
      team: team.members,
      score: gameScores[team.members[0]],
    }));

  const Side = (team) => {
    if (!isRyderCupStyle(yearGamesConfig)) return "left";
    const ryderCupTeams = Object.entries(yearGamesConfig.teams).sort();
    return team.some((userId) => ryderCupTeams[0][1].members.includes(userId))
      ? "left"
      : "right";
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      {teamScores
        .sort((a, b) => a.score.points - b.score.points)
        .reverse()
        .map(({ team, score }, i) => {
          const columns = SubColumns(course, tees);
          const rows = [
            IndexRow(score.combined),
            ParRow(score.combined),
            GrossRow(score.combined),
            NetRow(score.combined),
            PointsRow(score.combined),
          ];
          const subcomponent = (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={2}
            >
              <Table columns={columns} rows={rows} />
            </Box>
          );
          return (
            <CoopScorecard
              side={Side(team)}
              players={team.map((user) => GetUsername(user, yearGamesConfig))}
              progress={score.combined.gross.filter((x) => x).length}
              points={score.points}
              hole_points={score.hole_points}
              course={course}
              subcomponent={subcomponent}
              key={i}
            />
          );
        })}
    </Stack>
  );
}

export function BestBallScorecard({ game, year, userId }) {
  const gamesConfig = useGamesConfigContext();
  const scores = useScoresContext();

  const yearGamesConfig = gamesConfig[year];
  const yearScores = scores[year];
  const gameScores = yearScores.scores[game.game_id];

  const { course, tees } = GetCourse(
    game.best_ball.config.course,
    game.best_ball.config.tees,
    yearGamesConfig.courses
  );

  const team =
    game.best_ball.teams.find((team) => team.members.includes(userId))
      ?.members || [];
  const score = gameScores[userId];
  const combinedScore = score.combined;
  const userScore = score.team[userId];

  const columns = SubColumns(course, tees);
  const rows = [
    IndexRow(combinedScore),
    ParRow(combinedScore),
    { ...GrossRow(userScore), category: GetUsername(userId, yearGamesConfig) },
    { ...GrossRow(combinedScore), category: "Team Gross" },
    NetRow(combinedScore),
    PointsRow(combinedScore),
  ];
  const subcomponent = (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <Table columns={columns} rows={rows} />
    </Box>
  );

  const Side = (team) => {
    if (!isRyderCupStyle(yearGamesConfig)) return "left";
    const ryderCupTeams = Object.entries(yearGamesConfig.teams).sort();
    return team.some((userId) => ryderCupTeams[0][1].members.includes(userId))
      ? "left"
      : "right";
  };

  return (
    <Stack justifyContent="space-between" spacing={2}>
      <CoopScorecard
        side={Side(team)}
        players={team.map((user) => GetUsername(user, yearGamesConfig))}
        progress={combinedScore.gross.filter((x) => x).length}
        points={combinedScore.points}
        hole_points={combinedScore.hole_points}
        course={course}
        subcomponent={subcomponent}
      />
      <MedalHoleScorecard
        gameId={game.game_id}
        year={year}
        course={course}
        score={userScore}
      />
    </Stack>
  );
}
