import { styled, useTheme } from "@mui/material/styles";

import { CenteredBox } from "../components/base";

export function NetToPar({ toPar, children }) {
  const theme = useTheme();

  const Size = {
    SINGLE: "1.6rem",
    INNER: "1.4rem",
    OUTER: "1.8rem",
  };

  const SquareOutline = styled(CenteredBox, {
    shouldForwardProp: (prop) => !["size", "color"].includes(prop),
  })(({ size, color }) => ({
    width: `calc(2 * round(calc(${size} / 2), 1px))`,
    height: `calc(2 * round(calc(${size} / 2), 1px))`,
    border: `1px solid ${color}`,
    lineHeight: size,
  }));

  const CircleOutline = styled(SquareOutline)(() => ({ borderRadius: "50%" }));

  const Outline = ({ shape, doubled, color, children }) => {
    const Component =
      shape === "square"
        ? SquareOutline
        : shape === "circle"
          ? CircleOutline
          : new Error(`Unrecognized shape ${shape}`);

    if (doubled) {
      return (
        <Component size={Size.OUTER} color={color}>
          <Component size={Size.INNER} color={color}>
            {children}
          </Component>
        </Component>
      );
    }

    return (
      <Component size={Size.SINGLE} color={color}>
        {children}
      </Component>
    );
  };

  const colors = theme.palette.net_score;
  if (toPar <= -3) {
    return (
      <Outline shape="circle" doubled color={colors.double_eagle}>
        {children}
      </Outline>
    );
  }
  if (toPar === -2) {
    return (
      <Outline shape="circle" color={colors.eagle}>
        {children}
      </Outline>
    );
  }
  if (toPar === -1) {
    return (
      <Outline shape="circle" color={colors.birdie}>
        {children}
      </Outline>
    );
  }
  if (toPar === 0) {
    return children;
  }
  if (toPar === 1) {
    return (
      <Outline shape="square" color={colors.bogie}>
        {children}
      </Outline>
    );
  }
  if (toPar === 2) {
    return (
      <Outline shape="square" doubled color={colors.double_bogie}>
        {children}
      </Outline>
    );
  }
  return (
    <Outline shape="square" doubled color={colors.triple_bogie}>
      {children}
    </Outline>
  );
}
