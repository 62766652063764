import { Alert, AlertTitle, Box } from "@mui/material";

export function ErrorAlert({ error, onClose = () => {} }) {
  return (
    <Box m={2}>
      <Alert severity="error" onClose={onClose}>
        <AlertTitle>{error.title}</AlertTitle>
        {error.msg}
      </Alert>
    </Box>
  );
}
